import * as React from 'react';
import injectSheet from 'react-jss';
import { Spin } from 'antd';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
// Record<string, string>
const styles = () => ({
  loaderContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    zIndex: -1,
  },
});

interface LoaderProps {
  classes: {
    [X in keyof ReturnType<typeof styles>]: string;
  };
  zIndex?: number;
}

const Loader = (props: LoaderProps): React.ReactElement => {
  const { classes, zIndex } = props;
  return (
    <div className={classes.loaderContainer} style={{ zIndex: zIndex || -1 }}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
    </div>
  );
};

Loader.defaultProps = {
  zIndex: -1,
};

export default injectSheet(styles)(Loader);
