const config = {
	API_BASE_URL: 'https://shipperdashboardapp.shipsy.in',
	GOOGLE_OAUTH_CLIENT_ID: '803335163874-gu656t0eiv9f5vb9d7fk2ca87a3s7er4.apps.googleusercontent.com',
	CAPTCHA_SITE_KEY: '6LdZoMAZAAAAAIW6utzTiC2DjJWjFGRPRQ3lcFIg',
	REDIRECT_URL: 'https://{}.shipsy.app',
	REDIRECT_URL_NEW: 'https://{}.app.shipsy.io',
	SENTRY_DSN: 'https://276cc1c562364617854205f674ba7a4d@o134800.ingest.sentry.io/5398644',
	CUSTOM_FRONTEND_BASEURLS: [],
	GOOGLE_ANALYTICS_MEASUREMENT_ID: 'UA-86979717-11',
    EXTERNAL_API_HSFORMS: 'https://api.hsforms.com'
  };
  export default config;
