import { Switch, Route, Redirect } from 'react-router';
import * as React from 'react';
import Loader from '../components/common/Loader';
import { retry } from '../lib/helper';
import { SignupContextProvider } from '../components/Library/SignupContext';


const Main = React.lazy<any>(() => retry(() => import('../components/pages/Main')));
const GAuthPage = React.lazy<any>(() => retry(() => import('../components/pages/GoogleAuthPage/GAuthPage')));
const VerifyEmail = React.lazy<any>(() => retry(() => import('../components/pages/EmailVerification/VerifyEmail')));
const RouterV2 = React.lazy(() => import('../components/pages.v2/Router'))

const { Suspense } = React;

const RouterOutlet = (): React.ReactElement => {
  return (
    <SignupContextProvider>
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/oauth" component={GAuthPage} />
        <Route path="/verify-email" component={VerifyEmail} />
        <Route path="/" component={RouterV2}></Route>
        <Redirect from='*' to={'/'}></Redirect>
      </Switch>
    </Suspense>
    </SignupContextProvider>
  );
};

export default RouterOutlet;
