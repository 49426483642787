import Button from 'antd/lib/button';
import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { ERROR_PAGE_IMAGE_LINK } from '../../lib/Constants';
import FormStyles from '../../theming/styleSnippets/Forms.styles';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FallbackComponent: React.FC = ({ error, resetError, componentStack }: any): React.ReactElement => {
  const useStyles = createUseStyles(FormStyles);
  const classes = useStyles();
  return (
    <div className={classes.fallbackParent}>
      <div>
        <img src={ERROR_PAGE_IMAGE_LINK} alt="Error" />
      </div>
      <div className={classes.fallbackContentTop}>
        {/* TODO : replace with error code <Error Code:XXXX> */}
        OOPs something went wrong!
      </div>
      <div className={classes.fallbackContentBottom}>
        Our smart engineers are looking into it. Meanwhile you can try again!
      </div>
      <div style={{ textAlign: 'center' }}>
        <Button
          className={classes.fallbackButton}
          onClick={() => {
            window.location.href = window.location.origin;
          }}
        >
          Login
        </Button>
      </div>
    </div>
  );
};

export default FallbackComponent;
