import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from 'react-jss';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'antd/dist/antd.css';
import FallbackComponent from './components/common/FallbackComponent';
import { SENTRY_DSN } from './global';
import { getRouterBasename } from './lib/helper';
import './v2themes/styles.scss';
import 'react-input-range/lib/css/index.css';
Sentry.init({ dsn: SENTRY_DSN });

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<FallbackComponent />} showDialog={false}>
    <React.StrictMode>
      <ThemeProvider theme={{}}>
        <BrowserRouter basename={getRouterBasename()}>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}
