import moment from 'dayjs';
import Cookies from 'universal-cookie';
import url from 'url';
import { CUSTOM_FRONTEND_BASEURLS, REDIRECT_URL, REDIRECT_URL_NEW } from '../global';
import { LoginResponseInterface } from '../interfaces/interfaces';
import { getWorkspaceLoginRoute, getLoginRoute } from '../api/routes';
import { containerNumbersReverseMap } from '../components/common/SignUpForm.utils';
import { Signupform, SignUpStep2FormKeys } from '../components/pages.v2/components/Signup/SignupFormStep2/SignupFormStep2';
import { SignUpStep1FormKeys } from '../components/pages.v2/components/Signup/SignupFormStep1/SignupFormStep1';
import { GAEventActions, GAEventCatergory, GAEventLabels, OrganisationType, SignUpType } from './Types';
import decryptSignupDetails from '../api/signUpDetails';
import { DEFAULT_ERROR_MESSAGE, UTM_PARAMETERS } from './Constants';
import { signUp } from '../api/signUp';

const queryString = require('query-string');

export const getOrgPrettyName = (origin: string = window.location.origin): string | undefined => {
  const { hostname } = url.parse(origin);
  if (!hostname) return;
  if (
    hostname.indexOf('.') > 0 &&
    CUSTOM_FRONTEND_BASEURLS &&
    CUSTOM_FRONTEND_BASEURLS.length &&
    CUSTOM_FRONTEND_BASEURLS.includes(hostname.substring(hostname.indexOf('.') + 1))
  ) {
    const temp = hostname.substring(0, hostname.indexOf('.'));
    const x = temp.indexOf('-');
    return temp.substring(0, x);
  }
  if (
    hostname.indexOf('.') > 0 &&
    !['login', 'www', 'shipsy', 'eximportal'].includes(hostname.substring(0, hostname.indexOf('.')))
  ) {
    return hostname.substring(0, hostname.indexOf('.'));
  }
  return '';
};

export const getRouterBasename = (origin: string = window.location.origin): string => {
  return getOrgPrettyName(origin) ? 's' : '';
};

export const getExternalLoginUrl = (organisationId: string, domain?: string): string => {
  const redirectUrl = domain && domain.includes('shipsy.io') ? REDIRECT_URL_NEW : REDIRECT_URL;
  const origin = `${redirectUrl.replace('{}', organisationId)}`;
  const basePath = getRouterBasename(origin) ? `/${getRouterBasename(origin)}` : '';
  return `${origin}${basePath}${getLoginRoute()}`;
};

export const getExternalLoginUrlV2 = (organisationId: string, domain?: string): string => {
  const redirectUrl = domain && domain.includes('shipsy.io') ? REDIRECT_URL_NEW : REDIRECT_URL;
  const origin = `${redirectUrl.replace('{}', organisationId)}`;
  const basePath = getRouterBasename(origin) ? `/${getRouterBasename(origin)}` : '';
  return `${origin}${basePath}${getLoginRoute()}`;
};


export const toTitleCase = (string: string): string => {
  return string.replace(/\w\S*/g, `${string.charAt(0)?.toUpperCase()}${string.substr(1)?.toLowerCase()}`);
};

export const getDomain = (): string => {
  const { hostname } = window.location;
  if (hostname.includes('shipsy.io')) {
    return hostname;
  }
  const dotIndex = hostname.indexOf('.');
  if (dotIndex === -1) {
    return hostname;
  }
  return hostname.substr(dotIndex + 1);
};

export const setCookies = (res: {
  data?: LoginResponseInterface;
  isSuccess?: boolean;
  errorMessage?: string | undefined;
}): void => {
  const cookies = new Cookies();
  const cookiesMaxAge = 30;
  const expires = moment().add(cookiesMaxAge, 'second').toDate();
  const resData = res.data || {};

  Object.keys(resData).forEach((key) => {
    cookies.set(key, (resData as any)[key], {
      domain: getDomain(),
      maxAge: cookiesMaxAge,
      path: getWorkspaceLoginRoute(),
      expires,
    });
  });
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const retry = (fn: Function, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  }) as any;
};

export const validatePassword = (pass: string) => {
  // const regex = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{6,}$/;
  // const regex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{6,}$/;
  const regex = /^(?=.*[0-9]).{6,}$/;
  return regex.test(pass);
};

export const getParamsFromUrl = (search: string | undefined, params: string[]): Record<string, any> => {
  const result: Record<string, any> = {};
  if (!search) {
    return result;
  }

  params.forEach((eachParam) => {
    result[eachParam] = new URLSearchParams(search).get(eachParam);
  });

  return result;
};

export const getAllParamsFromUrl = (search: string): Record<string, any> => {
  if(!search) return {};

  const result: Record<string, any> = queryString.parse(search);
  return result;
}

export const getContainerNumberKey = (max: number): string => {
  if (max > 500) 
    return containerNumbersReverseMap[501];
  else {
    let lowerbond: number = 0;
    let ans: string = '';
    for (const upperBond of Object.keys(containerNumbersReverseMap)) {
        if ( max > lowerbond && max <= +upperBond) {
          ans = containerNumbersReverseMap[+upperBond];
          break;
        }
        lowerbond = +upperBond;
    };
    return ans;
  }
}

export const getSignupPayload = (form1: SignUpStep1FormKeys, form2: SignUpStep2FormKeys, token: string): Signupform => {
  const { email, companyName, name, contactNumber, password, agreement, dialCode, organisationType } = form1;
  const { industryType, jobRole, scheduleDemo, country, containerVolumeExports, containerVolumeImports, freightService, tradeRegion, containerVolume, serviceProvider } = form2;
  
  return {
    email,
    name,
    organisationType,
    companyName,
    agreement,
    industryType: industryType || undefined,
    jobRole: jobRole || undefined,
    password,
    captchaCode: token,
    redirectUrl: REDIRECT_URL,
    contactNumber,
    scheduleDemo,
    country,
    dialCode: dialCode?.label,
    containerVolumeExports: containerVolumeExports || undefined,
    containerVolumeImports: containerVolumeImports || undefined,
    freightService: freightService || undefined,
    tradeRegion: tradeRegion || undefined,
    containerVolume: containerVolume || undefined,
    serviceProvider: serviceProvider || undefined,
  };
}

export const getHsformsSignupPayload = (formStep1: SignUpStep1FormKeys, formStep2: SignUpStep2FormKeys) => {
  const getCookie = (name: string) => {
    let cookie: any = {};
    document.cookie.split(';').forEach(function(el) {
      let [k,v] = el.split('=');
      cookie[k.trim()] = v;
    })
    return cookie[name];
  }

  const { organisationType, companyName, email, name, contactNumber, dialCode } = formStep1;
  const {  jobRole,industryType, country, containerVolumeExports, containerVolumeImports, 
            serviceProvider, freightService, tradeRegion, containerVolume } = formStep2;
  return {
    fields: [
      {
          objectTypeId: "0-1",
          name: "exim_account_type",
          value: organisationType || '',
      },
      {
          objectTypeId: "0-1",
          name: "firstname",
          value: name || '',
      },
      {
          objectTypeId: "0-1",
          name: "phone",
          value: dialCode + contactNumber || '',
      },
      {
          objectTypeId: "0-1",
          name: "workspace_url",
          value: companyName || '',
      },
      {
          objectTypeId: "0-1",
          name: "email",
          value: email || '',
      },
      {
          objectTypeId: "0-1",
          name: "job_role___plg",
          value: jobRole || '',
      },
      {
          objectTypeId: "0-1",
          name: "industry",
          value: industryType || '',
      },
      {
          objectTypeId: "0-1",
          name: "country",
          value: country || '',
      },
      {
          objectTypeId: "0-1",
          name: "containers_exported_every_month",
          value: containerVolumeExports || '',
      },
      {
          objectTypeId: "0-1",
          name: "containers_imported_every_month",
          value: containerVolumeImports || '',
      },
      {
          objectTypeId: "0-1",
          name: "type_of_service_provider",
          value: serviceProvider || '',
      },
      {
          objectTypeId: "0-1",
          name: "monthly_shipment_volume",
          value: containerVolume || '',
      },
      {
          objectTypeId: "0-1",
          name: "type_of_freight_service",
          value: freightService?.toString() || '',
      },
      {
          objectTypeId: "0-1",
          name: "trade_region",
          value: tradeRegion?.toString() || '',
      },
    ],
    "context": {
      "hutk": getCookie('hutk'), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      "pageUri": window.location.href,
      "pageName": "EXIM Signup Form"
    }
  };
}

export const handleUtmSignup = async (urlMetaData?: Record<string, any>): Promise<any> => {
  let decodedData;
   if (urlMetaData?.details) {
    decodedData = await decryptSignupDetails(urlMetaData?.details, urlMetaData?.[UTM_PARAMETERS.UTM_SOURCE]);
  }
  const { email, name, companyName, mobileNumber: contactNumber, organisationType, iciciflag: iciciFlag } = getParamsFromUrl(decodedData, [
    'email',
    'name',
    'companyName',
    'mobileNumber',
    'organisationType',
    'iciciflag'
  ]);

  if (email && urlMetaData?.[UTM_PARAMETERS.UTM_SOURCE]) {
    const reqBody = {
      name,
      email,
      utmSource: urlMetaData?.[UTM_PARAMETERS.UTM_SOURCE],
      urlMetaData,
      companyName,
      organisationType,
      type: SignUpType.MANUAL,
      contactNumber,
      iciciFlag: !!+iciciFlag,
      urlDetails: decodedData,
    };

    const response = await signUp(reqBody);

    if (response.isSuccess && response.data) {
      const { id, email, name, signupType } = response.data;
      return {
        formData: {
          id,
          name,
          email,
          companyName,
          signupType,
          contactNumber,
        }
      };
    } else {
      return Promise.reject({
        message: response.errorMessage || DEFAULT_ERROR_MESSAGE
      });
    }
  }
};

export const sendEvent = (eventCategory: GAEventCatergory, eventAction: GAEventActions, eventLabel?: GAEventLabels, eventValue?: any) => {
  const ga = (window as any)['ga'];
  if (ga) {
    ga('send', {
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
      hitType: 'event',
    });
  }
};

export const debounceFunctionCall = (callBackFn: any, wait: number = 20) => {
  let timer: any;
  return  (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => callBackFn(...args), wait);
  };
}
