import {
  CreateSignupRequestDto,
  CreateSignupResponseDto,
  CompleteSignupRequestInterface,
  CompleteSignupResponseInterface,
  LoginResponseInterface,
  LoginRequestInterface,
  GetInvitedUserDetailsResponse,
  FetchOrgDetailsResponseInterface,
  HubspotFormField,
  CountryRequestDto,
} from '../interfaces/interfaces';
import { API_BASE_URL, EXTERNAL_API_HSFORMS, HUBSPOT_PORTAL_ID, HUBSPOT_SIGNUP_FORM_GUID } from '../global';
import {
  CREATE_SIGNUP_REQUEST,
  CREATE_SIGNUP_COMPLETE,
  REQUEST_LOGIN,
  REQUEST_VERIFY,
  REQUEST_FORGOT_PASSWORD,
  REQUEST_PASSWORD_RESET,
  GET_INVITED_USER_DETAILS,
  SIGNUP_INVITED_USER,
  FETCH_ORGANISATION_DETAILS,
  VERIFY_OTP,
  RESEND_OTP,
  SAML_INIT,
  VALIDATE_RESET_PASSWORD_LINK,
  FETCH_INDUSTRY_TYPES,
  FETCH_JOB_ROLES,
  FETCH_ORG_LIST,
  HS_FORMS_SIGNUP_ROUTE,
  FETCH_COUNTRIES_LIST,
  FETCH_DIAL_CODES_LIST,
  GET_INVITED_VENDOR_DETAILS,
} from './routes';
import { ApiResponse } from '../lib/Types';
import { GET, POST } from './apiHandler';

export const signUp = async (body: CreateSignupRequestDto): Promise<ApiResponse<CreateSignupResponseDto>> => {
  return POST(`${API_BASE_URL}${CREATE_SIGNUP_REQUEST}`, body);
};

export const getInvitationDetails = async (
  invitationKey: string,
  organisationId?: string | null,
  source?: string | null,
): Promise<ApiResponse<GetInvitedUserDetailsResponse>> => {
  const headers = { 'organisation-id': organisationId };
  return GET(`${API_BASE_URL}${GET_INVITED_USER_DETAILS}?invitationKey=${invitationKey}&source=${source}`, headers);
};

export const getVendorInvitationDetails = async (
  invitationKey: string,
): Promise<ApiResponse<GetInvitedUserDetailsResponse>> => {
  return GET(`${API_BASE_URL}${GET_INVITED_VENDOR_DETAILS}?invitationKey=${invitationKey}`);
};

export const signupInvitedUser = async (
  requestBody: any,
  organisationId: string
): Promise<ApiResponse<LoginResponseInterface>> => {
  const headers = {
    'organisation-id': organisationId,
  };

  return POST(`${API_BASE_URL}${SIGNUP_INVITED_USER}`, requestBody, headers);
};

export const getOrganisationDetails = async (
  organisationPrettyName: string
): Promise<ApiResponse<FetchOrgDetailsResponseInterface>> => {
  const headers = {
    'organisation-pretty-name': organisationPrettyName,
  };
  return GET(`${API_BASE_URL}${FETCH_ORGANISATION_DETAILS}`, headers);
};

export const signUpComplete = async (
  body: CompleteSignupRequestInterface
): Promise<ApiResponse<CompleteSignupResponseInterface>> => {
  return POST(`${API_BASE_URL}${CREATE_SIGNUP_COMPLETE}`, body);
};

export const signupHsforms = async (
  headers: {
    'Authorization': string,
    'content-type': string
  },
  body: {
    "fields": HubspotFormField[]
  }
)=>{
  return POST(`${EXTERNAL_API_HSFORMS}${HS_FORMS_SIGNUP_ROUTE}/${HUBSPOT_PORTAL_ID}/${HUBSPOT_SIGNUP_FORM_GUID}`,body,headers);
}
export const signIn = async (
  body: LoginRequestInterface,
  headers: { 'organisation-pretty-name'?: string }
): Promise<ApiResponse<LoginResponseInterface>> => {
  return POST(`${API_BASE_URL}${REQUEST_LOGIN}`, body, headers);
};

export const verifyOTP = async (
  body: LoginRequestInterface,
  headers: { 'organisation-pretty-name'?: string }
): Promise<ApiResponse<LoginResponseInterface>> => {
  return POST(`${API_BASE_URL}${VERIFY_OTP}`, body, headers);
};

export const resendOtp = async (
  body: LoginRequestInterface,
  headers: { 'organisation-pretty-name'?: string }
): Promise<ApiResponse<LoginResponseInterface>> => {
  return POST(`${API_BASE_URL}${RESEND_OTP}`, body, headers);
};

export const samlInit = async (
  body: { userType: string },
  headers: { 'organisation-pretty-name'?: string }
): Promise<ApiResponse<LoginResponseInterface>> => {
  return POST(`${API_BASE_URL}${SAML_INIT}`, body, headers);
};

export const verifyEmail = async (
  verificationLink: string | undefined
): Promise<ApiResponse<LoginResponseInterface>> => {
  return GET(`${API_BASE_URL}${REQUEST_VERIFY}${verificationLink}`);
};

export const forgotPassword = async (
  body: { email?: string },
  headers: { 'organisation-pretty-name'?: string }
): Promise<ApiResponse> => {
  return POST(`${API_BASE_URL}${REQUEST_FORGOT_PASSWORD}`, body, headers);
};

export const resetPassword = async (
  body: {
    newPassword?: string;
  },
  passwordResetLink: string
): Promise<ApiResponse<LoginResponseInterface>> => {
  return POST(`${API_BASE_URL}${REQUEST_PASSWORD_RESET}${passwordResetLink}`, body);
};

export const validateResetPasswordLink = async (body: {
  email: string;
  resetPasswordLink: string;
}): Promise<ApiResponse<LoginResponseInterface>> => {
  return POST(`${API_BASE_URL}${VALIDATE_RESET_PASSWORD_LINK}`, body);
};

export const oAuthStart = async (referrer: string): Promise<ApiResponse> => {
  return GET(`${API_BASE_URL}${'/oauth/start'}?referrer=${referrer}`);
};

export const fetchIndustryTypes = async (queryString: string): Promise<ApiResponse> => {
  return GET(`${API_BASE_URL}${FETCH_INDUSTRY_TYPES}?queryString=${queryString}`);
};

export const fetchJobRoles = async (queryString: string): Promise<ApiResponse> => {
  return GET(`${API_BASE_URL}${FETCH_JOB_ROLES}?queryString=${queryString}`);
};


export const fetchOrgsList = async (queryString: string, limit: number = 100): Promise<ApiResponse> => {
  return  GET(`${API_BASE_URL}${FETCH_ORG_LIST}?`+ new URLSearchParams({queryString, limit: String(limit)}).toString());
}

export const fetchCountriesList = async (dto: CountryRequestDto): Promise<ApiResponse> => {
  return GET(`${API_BASE_URL}${FETCH_COUNTRIES_LIST}`, {"organisation-id":"deforg" }, dto);
}

export const fetchDialCodesList = async (dto: CountryRequestDto): Promise<ApiResponse> => {
  return GET(`${API_BASE_URL}${FETCH_DIAL_CODES_LIST}`, {"organisation-id":"deforg" }, dto);
}