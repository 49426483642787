export enum SignUpType {
  MANUAL = 'MANUAL',
  GOOGLE = 'GOOGLE',
}

export enum OrganisationType {
  SHIPPER = 'SHIPPER',
  FF = 'FF',
  SHIPPING_LINE = 'SHIPPING_LINE',
  CHA = 'CHA',
  TRANSPORTER = 'TRANSPORTER',
}

export enum UserRole {
  MEMBER = 'MEMBER',
  ADMIN = 'ADMIN',
}

export enum UserType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum MonthlyShipmentVolume {
  LESS_THAN_FIFTY = 'LESS_THAN_FIFTY',
	FIFTY_TO_TWO_HUNDRED = 'FIFTY_TO_TWO_HUNDRED',
	TWO_HUNDRED_AND_ONE_TO_FIVE_HUNDRED = 'TWO_HUNDRED_AND_ONE_TO_FIVE_HUNDRED',
	FIVE_HUNDRED_AND_ONE_TO_THOUSAND = 'FIVE_HUNDRED_AND_ONE_TO_THOUSAND',
	THOUSAND_AND_ABOVE = 'THOUSAND_AND_ABOVE',
}

export enum GAEventCatergory {
  ShipmentTrackingSignUp = 'shipment-tracking_sign-up'
}

export enum GAEventActions {
  Form1Incomplete = 'form1_incomplete',
  Form2Incomplete = 'form2_incomplete',
  Form2Complete = 'form2_complete',
  ResendEmailClicked = 'email_verify_resend_clicked',
  EmailverifyIncomplete = 'emailverify_incomplete',
  EmailVerifyResendIncomplete = 'emailverifyresend_incomplete'
}

export enum GAEventLabels {
  SignupForm1 = 'sign-up-form1',
  SignupFrom2 = 'sign-up-form2',
  EmailVerify = 'emailverify',
  EmailVerifyResend = 'emailverifyresend'
}

export type ApiResponse<T = any> = {
  isSuccess: boolean;
  data?: T;
  errorMessage?: string;
};

interface LoginSettings {
  mobile_number_non_mandatory?: boolean;
  strategies?: {
    google?: boolean;
    saml?: boolean;
    password?: boolean;
  };
  password_policy?: {
    strong_password?: boolean;
    multi_factor?: string | null; // email
  };
  saml_config?: {
    redirect_url?: string;
    saml_certificate?: string;
  };
}
export interface AuthSettings {
  external_login_allowed?: boolean;
  internal?: LoginSettings;
  external?: LoginSettings;
}

export enum ContainerVolumeExportsImports {
  	ZERO = 'ZERO',	
    ONE_TO_TEN = 'ONE_TO_TEN',	
    ELEVEN_TO_TWENTY_FIVE = 'ELEVEN_TO_TWENTY_FIVE',
    TWENTY_FIVE_PLUS = 'TWENTY_FIVE_PLUS'
}

export enum ServiceProvider {
  "FF" = "FF",
  "SHIPPING_LINE" = "SHIPPING_LINE",
  "CHA" = "CHA",
  "TRANSPORTER" = "TRANSPORTER"
}

export const FreightService = ['Ocean', 'Road', 'Air', 'Rail'];

export const TradeRegion = ['North America', 'South America', 'Europe', 'Middle East', 'Africa', 'Australia', 'China', 'South East Asia', 'Others'];

export enum SignupSteps{
  STEP_1 = 1,
  STEP_2 = 2
}

export interface DialCodes {
  code: string;
  countryName: string;
}
