import axios from 'axios';
import { getErrorMessage, getErrorMessageForBlob } from './utils';

const apiProvider = axios.create({});

export const GET = async (url: string, headers?: any, params?: any, responseType?: any): Promise<any> => {
  try {
    const response = await apiProvider.get(url, {
      params,
      headers,
      responseType,
    });
    return {
      isSuccess: true,
      data: responseType === 'blob' ? response : response?.data?.data,
      status: response.status,
    };
  } catch (err) {
    const errorMessage = responseType === 'blob' ? await getErrorMessageForBlob(err) : getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
      status: err?.response?.status,
    };
  }
};

export const POST = async (url: string, body: any, headers?: any, isBlob = false): Promise<any> => {
  try {
    const meta = {
      headers,
    };
    if (isBlob) {
      Object.assign(meta, { responseType: 'blob' });
    }
    const response = await apiProvider.post(url, body, meta);
    const dataToReturn = {
      isSuccess: true,
      data: response?.data?.data,
    };
    if (isBlob) {
      const disposition = response.headers['content-disposition'];
      let filename = '';
      if (disposition) {
        const matches = disposition.match(/filename="(.*)"/);
        filename = matches?.length > 0 ? decodeURI(matches[1]) : '';
      }
      Object.assign(dataToReturn, { filename });
      dataToReturn.data = response.data;
    }
    return dataToReturn;
  } catch (err) {
    const errorMessage = isBlob ? await getErrorMessageForBlob(err) : getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
