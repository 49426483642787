const formStyles = () => ({
  parentDiv: { display: 'flex', justifyContent: 'center' },
  carouselImage: {
    height: '100%',
    width: '100%',
  },
  workspaceLabel: {
    '& ant-form-item-control-input-content': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    textAlign: 'left',
    marginBottom: '12px',
    color: '#333333',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 3,
  },
  workspaceInput: {
    textAlign: 'right',
    height: '32px',
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    margin: 0,
  },
  continueButton: {
    height: '48px',
    width: '124px',
    borderRadius: '4px',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    backgroundColor: '#006EC3',
    '&:hover': {
      backgroundColor: '#2A83D5',
    },
  },
  emailLabel: {
    '& .ant-form-item-explain': {
      height: '4px',
      minHeight: '4px',
      marginLeft: '4px',
      textAlign: 'left',
      fontFamily: 'Open Sans',
      fontSize: '12px',
      fontWeight: 600,
    },
    '& .ant-form-item-control-input': {
      minHeight: '42px',
    },
    '& .ant-select > .ant-select-selector': {
      height: '42px',
      paddingTop: '4px',
      paddingBottom: '4px',
    },
    '& .ant-select-selector .ant-select-selection-search': {
      top: '4px',
      bottom: '4px',
    },
    color: '#333333',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '16px',
  },
  emailInput: {
    fontSize: '14px',
    textAlign: 'left',
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    height: '42px',
  },
  leftSide: {
    width: '48.75%',
  },
  rightSide: {
    width: '51.25%',
    display: 'flex',
    flexDirection: 'column',
  },
  headerStyle: { 
    width: '100%',
    height: '80px',
    padding: '24px',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '16px',
    '&.progress': {
      display: 'grid',
      gridTemplateColumns: 'max-content 1fr max-content',
    },
  },
  progressBar: {
    height: '8px',
    width: '100%',
    backgroundColor: '#EAEAEA',
  },
  progressNum: {
    marginLeft: '0px',
    color: '#888888',
    fontSize: '12px',
    fontWeight: 400,
    '& .boldText': {
      fontSize: '20px',
      color: '#000000',
      fontWeight: 700,
    }
  },
  signInButton: {
    border: '1px #666666 solid',
    borderRadius: '2px',
    color: '#666666',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
  },
  formHeader: {
    textAlign: 'left',
    color: '#333333',
    fontFamily: 'Open Sans',
    fontSize: '24px',
    fontWeight: 'bold',
    paddingBottom: '16px',
  },
  content: {
    color: '#666666',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    textAlign: 'left',
  },
  iframe: {
    display: 'none',
  },
  carouselText: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontSize: '21px',
    fontWeight: 600,
    textAlign: 'center',
  },
  carouselContent: {
    padding: '21.48% 6.37%',
  },
  logoStyle: {
    position: 'absolute',
    zIndex: '100',
    left: '24px',
    top: '24px',
    width: '30.08%',
  },
  carouselStyle: {
    '& .ant-carousel': {
      height: '100%',
    },
  },
  navInfo: {
    '@media screen and (max-width: 600px)': {
      display: 'none',
    },
    lineHeight: '2',
    textAlign: 'right',
  },
  blueBar: {
    background: '#165594',
    '@media (min-width: 768px)': { display: 'none' },
    width: '100%',
    height: '80px',
    padding: '24px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  noLabelFields: {
    marginLeft: 0,
    fontSize: '10px',
  },
  responsivePadding: {
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  signUpForm: {
    width: '95%',
    padding: '17% 14.64% 0 13.32%',
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  signInForm: {
    width: '100%',
    padding: '12.39% 14.64% 0 13.32%',
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  signUpDetailsForm: {
    width: '100%',
    padding: '5.6% 9.69% 0 13.32%',
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  signInWorkspaceForm: {
    width: '100%',
    padding: '16% 7.91% 0 13.32%',
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  resetPasswordForm: {
    width: '100%',
    padding: '22.94% 6.44% 0 13.32%',
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  forgotPasswordForm: {
    width: '100%',
    padding: '24% 7.32% 0 13.32%',
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  emailVerification: {
    width: '100%',
    padding: '0 10.98% 0px 0',
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  forgotPassDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '@media (min-width: 576px)': { width: '83.33%' },
  },
  greenMessage: {
    paddingBottom: '32px',
    color: '#31B268',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    textAlign: 'left',
    '@media (min-width: 576px)': { width: '83.33%' },
  },
  workspaceHeader: {
    paddingBottom: '16px',
    width: '83.3%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  redMessage: {
    paddingTop: 24,
    paddingBottom: '24px',
    color: '#DC3C42',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    textAlign: 'left',
    '@media (min-width: 576px)': { width: '83.33%' },
  },
  googleButtonStyles: {
    fontSize: '12px',
    boxShadow: 'none',
    border: '1px solid #333333',
    borderRadius: '4px',
    color: '#111111',
    padding: '0px 14%',
    fontWeight: 600,
    width: '100%',
    height: '42px',
  },
  fallbackParent: {
    height: '100%',
    width: '100%',
    padding: '9.36% 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fallbackContentTop: {
    textAlign: 'center',
    color: '#333333',
    fontFamily: 'Open Sans',
    fontSize: '18px',
    fontWeight: 600,
  },
  fallbackContentBottom: {
    textAlign: 'center',
    color: '#333333',
    fontFamily: 'Open Sans',
    fontSize: '18px',
    paddingBottom: '32px',
  },
  fallbackButton: {
    height: '36px',
    width: '134px',
    borderRadius: '4px',
    backgroundColor: '#006EC3',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#2A83D5',
    },
  },
  selectStyle: {
    fontWeight: 'normal',
    fontSize: '14px',
    textAlign: 'left',
    height: '42px',
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  popover: {
    '& .ant-popover-inner-content': {
      backgroundColor: '#FFF3C6',
    },
  },
  loginTextStyle: {
    width: 129,
    color: '#797979',
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: 600,
    letterSpacing: 0,
    margin: '8px, 0px',
  },
  signUpPartitionText: {
    color: '#999999',
    fontSize: '12px',
    fontStyle: 'italic',
    margin: '0 12px',
  },
  signUpPartition: {
    borderTop: '1px solid #CCCCCC',
    width: '46%',
  },
  signUpLabel: {
    fontSize: '10px',
    color: '#333333',
    marginBottom: '4px',
  },
  continueArrow: {
    position: 'absolute !important',
    right: '8px',
    top: '14px',
    color: '#ffffff',
  },
  continueSignUp: {
    width: '80%',
    borderRadius: '4px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    backgroundColor: '#006EC3',
    height: '42px',
    '&:hover': {
      backgroundColor: '#2A83D5',
      position: 'relative',
    },
    '&:focus': {
      position: 'relative',
    },
    position: 'relative',
  },
  username: {
    color: '#31B268',
    fontSize: '12px',
    marginBottom: '36px',
    fontWeight: 600,
  },
  containerCount: {
    border: '1px solid #CCCCCC',
    color: '#111111',
    fontSize: '12px',
    borderRadius: '5px',
    padding: '16px 20px',
    textAlign: 'center',
    margin: '16px 16px 16px 0',
    cursor: 'pointer',
    fontWeight: 600,
    maxWidth: '21%',
    '&.active': {
      border: '1px solid #1890ff',
      color: '#1890ff',
    },
  },
  jobRoleOptions: {
    border: '1px solid #CCCCCC',
    color: '#111111',
    fontSize: '12px',
    borderRadius: '5px',
    padding: '16px 20px',
    textAlign: 'center',
    margin: '16px 16px 16px 0',
    cursor: 'pointer',
    fontWeight: 600,
    minWidth: 'fit-content',
    '&.active': {
      border: '1px solid #1890ff',
      color: '#1890ff',
    },
  },
  reEnterEmail: {
    color: '#006EC3',
    cursor: 'pointer',
    fontWeight: 600,
  },
  dialCodeDropdown: {
    width: '160px !important',
    '& .ant-select-item-option': {
      fontSize: '12px',
      '&:hover': {
        backgroundColor: 'rgb(0, 110, 195, 0.05)',
        color: '#006EC3',
      },
    },
  },
  dialCodeLabel: {
    width: '70%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingRight: 10,
  },
  dialCodeSearch: {
    margin: '6px 10px 14px 10px',
    width: '140px',
    border: '1px solid #999999',
    borderRadius: '4px',
    backgroundColor: '#F9F9F9',
    '& .ant-input:placeholder-shown': {
      backgroundColor: '#F9F9F9 !important',
      color: '#999999',
    },
    '& .ant-input': {
      backgroundColor: '#F9F9F9 !important',
    },
  },
});

export default formStyles;
