export const CREATE_SIGNUP_REQUEST = '/join/request';
export const CREATE_SIGNUP_COMPLETE = '/join/v2/signup';
export const REQUEST_LOGIN = '/join/login';
export const REQUEST_VERIFY = '/join/verify/';
export const REQUEST_FORGOT_PASSWORD = '/join/password/forgot';
export const REQUEST_PASSWORD_RESET = '/join/password/reset/';
export const VALIDATE_RESET_PASSWORD_LINK = '/join/forgot/validate-url';
export const GET_INVITED_USER_DETAILS = '/join/invite';
export const GET_INVITED_VENDOR_DETAILS = '/join/invite-vendor';
export const SIGNUP_INVITED_USER = '/join/invite/signup';
export const FETCH_ORGANISATION_DETAILS = '/orgconfig/fetchOrganisationDetails';
export const VERIFY_OTP = '/join/verify-otp';
export const RESEND_OTP = '/join/resend-otp';
export const DECRYPT_SIGNUP_DETAILS = '/join/signup-details';
export const SAML_INIT = '/saml/init';
export const FETCH_INDUSTRY_TYPES = '/masterdata/industry-type';
export const FETCH_JOB_ROLES = '/masterdata/job-role';
export const FETCH_ORG_LIST = '/join/orgs';
export const FETCH_COUNTRIES_LIST = '/country';
export const FETCH_DIAL_CODES_LIST = '/country/dialCodes';

export const getSignUpRoute = (): string => '/signup';
export const getInvitationSignUpRoute = (): string => '/signup-invite';
export const getLoginRoute = (): string => '/login';
export const getWorkspaceLoginRoute = (): string => '/';
export const getForgotPasswordRoute = (): string => '/forgot-password';
export const getResetPasswordRoute = (): string => '/reset-password';
export const getVerifyOtpRoute = () => '/verify';
export const getVerifyEmailRoute = () => '/verify-email-address';

export const HS_FORMS_SIGNUP_ROUTE = '/submissions/v3/integration/submit';