export const ERROR_PAGE_IMAGE_LINK =
  'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/signup-dashboard/Error.gif';
export const ERROR_PAGE_REDIRECTION_LINK = 'https://shipsy.in';
export const DEFAULT_ERROR_MESSAGE = 'Could not connect. Please check your internet connection';
export const UTM_PARAMETERS = {
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm-medium',
  UTM_CAMPAIGN: 'utm_campaign',
};

export const CAMPAIGN_TEST_TYPE = 'self_signup_test';
export const CAMPAIGN_TYPE = 'self_signup';
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const FULL_NAME_REGEX = /^[a-zA-Z ]*$/;

export const COUNTRY_CODES_AT_TOP = ['IND','USA'];


export const UTM_SOURCES = {
  SHIPSY_IO : 'shipsy.io',
  ICICI : 'icici',
  IDFC : 'idfc',
  HDFC : 'hdfcbank',
  SMBCAMPAIGN : 'smbcampaign',
};

export const UTM_SOURCE_DETAILS_KEY_MAP = {
  [UTM_SOURCES.SHIPSY_IO] : 'details',
  [UTM_SOURCES.ICICI] : 'details',
  [UTM_SOURCES.IDFC] : 'details',
  [UTM_SOURCES.HDFC] : 'data',
  [UTM_SOURCES.SMBCAMPAIGN] : 'details',
};

export const THIRD_PARTY_INTEGRATIONS = {
  [UTM_SOURCES.ICICI] : {
    showCoBrandedLogo: true,
    logoUrl: 'https://shipsy-public-assets.s3.us-west-2.amazonaws.com/frontend-assets/icici/Logo.jpg'
  },
  [UTM_SOURCES.IDFC] : {
    showCoBrandedLogo: true,
    logoUrl: 'https://shipsy-public-assets.s3.us-west-2.amazonaws.com/frontend-assets/idfc/Logo.jpeg'
  },
  [UTM_SOURCES.HDFC] : {
    showCoBrandedLogo: true,
    logoUrl: 'https://shipsy-public-assets.s3.us-west-2.amazonaws.com/frontend-assets/hdfc/Logo.jpeg'
  },
}


export const UTM_CAMPAIGN_PAGE_DATA = {
  [UTM_SOURCES.SMBCAMPAIGN] : {
    others : {
      text1 : "Book, Track, and Manage All Your International\
      Container Shipments through a Single Window",
      text2 : "Secure the best freight rates, track all your containers\
      across all carriers in real-time,and plan shipments weeks in advance\
      via a unified platform",
      benefits : [
        {
          percentage : 12,
          name : "Savings via spot rates and freight procurement automation"  
        },
        {
          percentage : 64,
          name : "Enhancement in customer experience"  
        },
        {
          percentage : 80,
          name : "Lesser incidental costs with real-time container tracking"  
        }
      ]
    },
    faq : [
      {
        question: "I am already working with CHAs and freight forwarders directly. Why should I use Shipsy?",
        ans: "Shipsy empowers you to book, track, and manage your container shipments all in one place, which\
        means you can obtain freight rates effortlessly without spending hours on phone and emails with\
        individual CHAs and forwarders. You can also track all your container shipments through one interface,\
        even for shipments not booked via Shipsy. We’re confident that you will save on freight costs and a lot\
        of time by using Shipsy’s international logistics management platform."
      },
      {
        question: "What can the Shipsy platform do for my international business?",
        ans: "Shipsy’s international logistics management platform leverages deep integrations with the logistics\
        ecosystem to enable growing global businesses to save time and money throughout the freight\
        operations lifecycle - ranging from freight procurement through spot rate searches & inquiries to\
        checking vessel schedules to real-time container & shipment status tracking."
      },
      {
        question: "What happens if I reach my plan’s usage limit?",
        ans: "When you reach your plan’s usage limit, you will receive a notification with the option to upgrade your\
        plan. Once you upgrade, you will be able to perform more spot rate searches, create more inquiries,\
        and add more containers per the new plan."
      },
      {
        question: "What is your cancellation policy?",
        ans: "Our platform is usually billed on a monthly basis. Once your current billed month is in progress, you may\
        use the platform for the rest of the said billing period. If you choose not to renew the following month,\
        you will be automatically rolled back to the free plan."
      }
    ]
  }
}
