// eslint-disable-next-line @typescript-eslint/no-var-requires
const config = require('./config').default;

const {
  API_BASE_URL,
  EXTERNAL_API_HSFORMS,
  GOOGLE_OAUTH_CLIENT_ID,
  OAUTH_BASE_URL,
  SENTRY_DSN,
  CAPTCHA_SITE_KEY,
  REDIRECT_URL,
  REDIRECT_URL_NEW,
  CUSTOM_FRONTEND_BASEURLS,
  GOOGLE_ANALYTICS_MEASUREMENT_ID,
  PASSWORD_SECRET,
  EXIM_FRONTEND_BASE_URL,
  ENV,
  HUBSPOT_PORTAL_ID,
  HUBSPOT_SIGNUP_FORM_GUID,
} = config;

export {
  API_BASE_URL,
  EXTERNAL_API_HSFORMS,
  GOOGLE_OAUTH_CLIENT_ID,
  OAUTH_BASE_URL,
  SENTRY_DSN,
  CAPTCHA_SITE_KEY,
  REDIRECT_URL,
  CUSTOM_FRONTEND_BASEURLS,
  GOOGLE_ANALYTICS_MEASUREMENT_ID,
  REDIRECT_URL_NEW,
  PASSWORD_SECRET,
  EXIM_FRONTEND_BASE_URL,
  ENV,
  HUBSPOT_PORTAL_ID,
  HUBSPOT_SIGNUP_FORM_GUID
};
