import React, { useEffect } from 'react';
// import './App.css';
import RouterOutlet from './routing/RouterOutlet';
import fullStory from './addons/fullstory';
import initGoogleAnalytics from './addons/gaService';
import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from './global';


function App(): React.ReactElement {
  const initFullStory = () => {
    fullStory();
  };

  useEffect(() => {
    initFullStory();
    initGoogleAnalytics(GOOGLE_ANALYTICS_MEASUREMENT_ID);
  }, []);

  return (
    <div className="App">
      {/* <div style={{ background: `url(${backgroundImg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
        <RouterOutlet />
      </div> */}
      <RouterOutlet />
    </div>
  );
}

export default App;
