import { GET } from './apiHandler';
import { DECRYPT_SIGNUP_DETAILS } from './routes';
import { API_BASE_URL } from '../global';

const decryptSignupDetails = async (message: string, source: string): Promise<string> => {
  const encodedMessage = encodeURIComponent(message);
  const url = `${API_BASE_URL}${DECRYPT_SIGNUP_DETAILS}?message=${encodedMessage}&source=${source}`;
  const result = await GET(url);
  return result.data || '';
};

export default decryptSignupDetails;
