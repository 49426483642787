const errorMessages = {
  isEmpty: 'This field can’t be empty.',
  panNumber: 'Should have 10 Alphanumeric Characters.',
  password: 'At least 8 characters are required',
  name: 'Please enter alphabets only',
  required: '{} is required',
  pan: 'PAN Number is invalid.',
  mobileNumber: 'Mobile number should be of 10 digits',
  email: 'Oops! That email is not valid.',
};

const SignUpStepsMap = {
  NAME_AND_EMAIL: 0,
  INDUSTRY: 1,
  JOB_ROLE: 2,
  CONTAINER_NUMBERS: 3,
  PASSWORD: 4,
  CHECK_EMAIL: 5,
};

const containerNumbers: Record<string, string> = {
  ONE_TO_TEN: '1-10',
  ELEVEN_TO_TWENTY_FIVE: '11-25',
  TWENTY_SIX_TO_HUNDRED: '26-100',
  HUNDRED_AND_ONE_TO_FIVE_HUNDRED: '101-500',
  FIVE_HUNDRED_AND_ABOVE: '500+',
};

const containerNumbersReverseMap: Record<number, string> = {
  10: 'ONE_TO_TEN',
  25: 'ELEVEN_TO_TWENTY_FIVE',
  100: 'TWENTY_SIX_TO_HUNDRED',
  500: 'HUNDRED_AND_ONE_TO_FIVE_HUNDRED',
  501: 'FIVE_HUNDRED_AND_ABOVE'
};

export { errorMessages, SignUpStepsMap, containerNumbers, containerNumbersReverseMap};
