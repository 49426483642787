import { createContext, useState } from "react";
import * as React from 'react';
import { SignUpStep1FormKeys } from "../pages.v2/components/Signup/SignupFormStep1/SignupFormStep1";
import { SetState, useGenericState } from "./UseGenericState";
import { SignUpStep2FormKeys } from "../pages.v2/components/Signup/SignupFormStep2/SignupFormStep2";
import { SignupFormInviteKeys } from "../pages.v2/components/SignupInvite/SignupInvite.component";
import { OrganisationType } from "../../lib/Types";
import { CountryObjectList } from "../pages.v2/components/Signup/SignupFormStep2/countryObjectList.tsx";
import { CountryInterface } from "../../interfaces/interfaces";

interface SignupForm extends SignUpStep1FormKeys, SignUpStep2FormKeys, SignupFormInviteKeys {
    firstStepDone: boolean;
    secondStepDone: boolean;
    isUtmSignupFlow: boolean;
    isSignupInviteFlow: boolean;
    isShipper: boolean;
    currentStep: number;
    countriesList: CountryInterface[];
    isMobileDevice: boolean;
    source?: string;
} 

const SignupContext = createContext<[SignupForm, SetState<SignupForm>] | undefined>(undefined);

export interface UseSignupContextProps {
    children: React.ReactNode | React.ReactNode[];
}

const SignupContextProvider = (props: UseSignupContextProps) => {
    const [state, setState] = useGenericState<SignupForm>({
        name: '',
        email: '',
        companyName: '',
        contactNumber: '',
        password: '',
        industryType: '',
        jobRole: '',
        signupInviteCompanyName: '',
        invitationKeys: '',
        scheduleDemo: true,
        firstStepDone: false,
        secondStepDone: false,
        isUtmSignupFlow: false,
        agreement: false,
        isSignupInviteFlow: false,
        country: '',
        dialCode: { key: '', label: '' },
        organisationType: OrganisationType.SHIPPER,
        containerVolumeExports: '',
        containerVolumeImports: '',
        isShipper: true,
        freightService: [],
        tradeRegion: [],
        currentStep: 1,
        countriesList: [],
        containerVolume: '',
        serviceProvider: '',
        isMobileDevice: false,
    });

    const { children } = props;

    return <SignupContext.Provider value={[state, setState]}>
        {children}
    </SignupContext.Provider>
}

const useSignupContext = (): [SignupForm, SetState<SignupForm>] => {
    const context = React.useContext(SignupContext);
    if (!context) {
        throw new Error('No context found');
    }
    return context;
}

export {
    SignupContextProvider,
    useSignupContext,
    SignupContext,
}
